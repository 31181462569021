// Please set this for deployment !!!!!
// Options [local, dev, staging, prod]
const build = 'staging'
// Options [local, dev, staging, prod]

const apiHostname = process.env.REACT_APP_API_ENDPOINT
const googleMapApi = process.env.REACT_APP_API_GOOGLE
const apiVersion = process.env.REACT_APP_API_SAAS_VER
const apiPortalUrl = process.env.REACT_APP_API_PORTAL_URL

export {
  build,
  apiHostname,
  googleMapApi,
  apiPortalUrl,
  apiVersion
}
