import jwt from 'jsonwebtoken'
import localStorage from './localStorage'
import sessionStorage from './sessionStorage'
import validator from './validator'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  setCurrentToken (token) {
    return localStorage.setItem('token', token)
  },
  getCurrentHash () {
    try {
      const tokenHash = localStorage.getItem('hash')
      const hash = jwt.verify(tokenHash, this.getCurrentToken())

      return hash
    } catch (e) {
      console.log('portal hsg err', e)
      return {}
    }
  },
  setCurrentHash (hash) {
    const token = this.getCurrentToken()
    const tokenHash = jwt.sign(hash, token)
    localStorage.setItem('hash', tokenHash)
  },
  getCurrentUser () {
    try {
      const tokenUser = localStorage.getItem('user-token')
      const user = jwt.verify(tokenUser, this.getCurrentToken())

      return user
    } catch (e) {
      console.log('portal cu err', e)
      return {}
    }
  },
  getCurrentRedirectToken () {
    return localStorage.getItem('redirect-token')
  },
  setCurrentRedirectToken (token) {
    return localStorage.setItem('redirect-token', token)
  },
  setCurrentUser (user) {
    const token = this.getCurrentToken()
    const tokenUser = jwt.sign(user, token)
    localStorage.setItem('user-token', tokenUser)
  },
  isSignedIn () {
    const token = this.getCurrentToken()
    return token && token !== 'undefined' && token.length > 0
  },
}
