import { auth, localStorage, queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlUser = '/private/api/user'

export default {
  get () {
    return request(`${urlUser}`)
  },
}
