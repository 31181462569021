import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Alert, Button, Checkbox, Form, Icon, Input, notification, Pagination, Row, Col, Space, Skeleton, Switch, Spin, Table } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../components'
import { authService, loginService } from '../../../services'
import { auth, formatter, localStorage, sessionStorage, validator } from '../../../util'

import 'antd/dist/antd.min.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

const pageSize = 20
const ERROR_INVALID_LOGIN = 'Invalid Login.'
const MSG_EMAIL_SENT = 'Temporary Password has been sent to your registered email.'

const isEnterKey = (e) => {
  return e && e.key === 'Enter'
}

const isMouseClick = (e) => {
  return e && e.key === undefined && e.currentTarget.tagName.toLowerCase() === 'button'
}

function ForgotPassword () {
  const [isUpdate, setIsUpdate] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [resetCount, setResetCount] = useState(0)

  const [alert, setAlert] = useState({
    message: 'Incorrect username and/or password.',
    show: false,
    type: 'error'
  })
  const [resetAlert, setResetAlert] = useState({
    message: MSG_EMAIL_SENT,
    show: false,
    type: 'warning'
  })

  const [form] = Form.useForm()
  const navigate = useNavigate()

  useEffect(() => {
    const user = auth.getCurrentUser()

    if (user && user.id) {
      navigate('/', { replace: true })
      window.location.replace(`/`)
    }
  }, [])

  async function onSubmitUser (e) {
    if (isEnterKey(e) || isMouseClick(e)) {
      form.validateFields()
        .then(async (values) => {
          if (resetCount < 10) {
            setIsUpdate(true)
            const r = await loginService.resetPassword(values)

            if (r && r.email) {
              setIsResetPassword(true)
              setResetAlert({
                message: MSG_EMAIL_SENT,
                show: true,
                type: 'warning'
              })
            } else {
              setResetCount(resetCount + 1)
            }

            setIsUpdate(false)
          }
        })
        .catch(errorInfo => {
          setResetCount(resetCount + 1)
          setIsUpdate(false)
        })
    }
  }

  async function onSubmit (e) {
    if (isEnterKey(e) || isMouseClick(e)) {
      sessionStorage.removeItem('tokenExpired')
      sessionStorage.removeItem('tokenRevoked')
      sessionStorage.removeItem('unauthorized')
      setAlert({ ...alert, show: false })

      form.validateFields()
        .then(async (values) => {
          if (isUpdate) return

          setIsUpdate(true)

          const { username, password, new_password, remember_me } = values
          try {
            const r = await loginService.login({ username, password, new_password, remember_me })
            const { reset_password: rspw, hash, token, user, invalid = false } = r

            if (rspw) {
              setIsResetPassword(true)
            } else if (!invalid) {
              localStorage.setItem('redirected', false)
              localStorage.setItem('rememberMe', remember_me || false)
              auth.setCurrentToken(token)
              auth.setCurrentUser(user)
              auth.setCurrentHash(hash)
              sessionStorage.removeItem('tokenExpired')
              sessionStorage.removeItem('tokenRevoked')
              sessionStorage.removeItem('unauthorized')
              navigate('/', { replace: true })
            } else {
              let message = ''
              if (validator.isNotEmptyArray(r.errors)) {
                message = formatter.toErrorMessage(r.errors)
              } else {
                message = ERROR_INVALID_LOGIN
              }
              setAlert({ ...alert, message, show: true })
            }
          } catch (e) {
            const { response } = e
            let message = ''
            if (response) {
              const errorMsg = formatter.toErrorMessage(response)
              message = errorMsg
            }

            setAlert({ ...alert, message, show: true })
          }

          setIsUpdate(false)
        })
        .catch(errorInfo => {
          setIsUpdate(false)
        })
    }
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Reset Password not successful',
      description: 'Unable to reset password successfully.'
    })

    setIsUpdate(false)
  }

  function hideAlert () {
    setAlert({ ...alert, show: false })
  }

  async function validateNewPassword (rule, value, callback) {
    if (value === null || value === undefined || value === '') {
      throw new Error('Please enter new password')
    } else if (validator.checkPassword(value)) {
      throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
    } else if (value.length < 8) {
      throw new Error('Please enter at least 8 characters')
    }
  }

  async function validateConfirmPassword (rule, value, callback) {
    const newPassword = form.getFieldValue('new_password')
    if (value === null || value === undefined || value === '') {
      throw new Error('Please enter confirm password')
    } else if (newPassword !== value) {
      throw new Error('Your confirm password does not match new password')
    }
  }

  return (
    <div className='login'>
      <div className='login-box'>
        <Row>
          <Col sm={24} lg={24} className='box'>
            <div className='row-center'>
              <img className='logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-trans.png'} />
              <div className='title-sub'>Forgot Password</div>
            </div>
            <div className='center'>
              {alert.show ? (
                <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message={alert.message}
                  type={alert.type}
                  showIcon
                />
              ) : null}

              {resetAlert.show
                ? <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message={resetAlert.message}
                  type={resetAlert.type}
                  showIcon
                />
                : null}

              <Form
                form={form}
                layout='vertical'
                name='login'
                onFinish={isResetPassword ? onSubmit : onSubmitUser}
                onFinishFailed={onSubmitFailed}
              >
                <div style={isResetPassword ? { display: 'none' } : {}}>
                  <FormItem
                    className='input-text'
                    hasFeedback
                    label=''
                    name='username'
                    rules={[
                      { required: true, message: 'Please enter your username' }
                    ]}
                  >
                    <Input
                      disabled={isUpdate}
                      onKeyPress={isResetPassword ? null : onSubmitUser}
                      prefix={<UserOutlined style={{ marginRight: '15px' }} />}
                      placeholder='Username (Email or Code)'
                    />
                  </FormItem>
                </div>

                {isResetPassword
                  ? <div>
                    <FormItem
                      className='input-text'
                      hasFeedback
                      label=''
                      name='password'
                      rules={[
                        { required: true, message: 'Temp Password is required' },
                      ]}
                    >
                      <Input.Password
                        disabled={isUpdate}
                        prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                        placeholder='Temp Password'
                      />
                    </FormItem>
                    <FormItem
                      className='input-text'
                      hasFeedback
                      label=''
                      name='new_password'
                      rules={[
                        { required: true, message: ' ' },
                        { validator: validateNewPassword }
                      ]}
                    >
                      <Input.Password
                        disabled={isUpdate}
                        prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                        placeholder='New Password'
                      />
                    </FormItem>
                    <FormItem
                      className='input-text'
                      hasFeedback
                      label=''
                      name='confirm_password'
                      rules={[
                        { required: true, message: ' ' },
                        { validator: validateConfirmPassword }
                      ]}
                    >
                      <Input.Password
                        disabled={isUpdate}
                        prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                        placeholder='Confirm Password'
                      />
                    </FormItem>

                    <Button
                      className='button'
                      shape='round'
                      type='primary'
                      loading={isUpdate}
                      onClick={onSubmit}
                    >
                      {isUpdate ? 'Update and Logging in...' : 'Update New Password and Login'}
                    </Button>
                  </div>
                  : <div>
                    <Button
                      className='button'
                      shape='round'
                      type='primary'
                      loading={isUpdate}
                      onClick={onSubmitUser}
                    >
                      {isUpdate ? 'Resetting...' : 'Reset Password'}
                    </Button>

                    {!isUpdate
                      ? <div className='center' style={{ marginTop: '20px' }}>
                        <Link to='/login' onClick={hideAlert}>Back to Login</Link>
                      </div>
                      : null}
                  </div>}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <div className='copyright'>
        <div className='footer'>
          Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2022 Pumpkin IT. All rights reserved.
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
