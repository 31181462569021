import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { auth } from './util'
import routes from './routes'

import App from './modules/App'
import Login from './modules/Login'
import ForgotPassword from './modules/Login/forgotPassword'

import reducers from './states/reducers'
import root from './states/sagas'

import './index.less'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='*' element={(isLoggedIn() ? <App /> : <Navigate to='/login' replace />)} />
      </Routes>
    </Router>
  </Provider>,
  document.getElementById('root')
)

function isLoggedIn () {
  return auth.isSignedIn()
}
