import React, { Component } from 'react'
import './styles.css'

function Notification (props) {
  const { type, title, message } = props
  const classSelection = !type
    ? ''
    : type === 'error'
      ? 'notification-box-error'
      : type === 'warning'
        ? 'notification-box-warning'
        : type === 'success'
          ? 'notification-box-success'
          : ''

  const iconSelection = !type
    ? ''
    : type === 'error'
      ? 'iconfont icon-55 icon'
      : type === 'warning'
        ? 'iconfont icon-51 icon'
        : type === 'success'
          ? 'iconfont icon-52 icon'
          : ''

  return (
    <div className={`notification-box ${classSelection}`}>
      <div className='icon'>
        <i className={iconSelection} />
      </div>
      <div className='content'>
        <div className='title'>{title}</div>
        <div className='message'>{ message }</div>
      </div>
    </div>
  )
}

export default Notification
