import React, { useEffect, useState } from 'react'
import { useNavigate, BrowserRouter as Router, Route } from 'react-router-dom'

import { RiNotification2Fill, RiMenuFill, RiMenu2Fill, RiBuilding4Fill, RiFunctionLine, RiShieldUserFill, RiShieldUserLine } from 'react-icons/ri'

import { routes } from '../../../routes'
import { PlatformSites, SSOPlatformType } from '../../../constants'
import { Notify, Page, Panel, Total } from '../../../components'
import { auth, localStorage, sessionStorage, formatter, validator } from '../../../util'
import { loginService, redirectService, userService } from '../../../services'
import { Button, Icon, Modal, notification, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { UserStore } from '../../../stores'
import { build, apiHostname } from '../../../config'

import './styles.css'

function Dashboard () {
  const navigate = useNavigate()

  const [selectedPlatform, setSelectedPlatform] = useState({})
  const [showRedirectModal, setShowRedirectModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {

  }, [])

  const list = UserStore.useState(e => e.userPlatformList)
  const isUserLoading = UserStore.useState(e => e.isUserLoading)

  function triggerRedirectModal (showRedirectModal, item = {}) {
    setSelectedPlatform(item)
    setShowRedirectModal(showRedirectModal)
  }

  async function redirect () {
    setIsUpdate(true)
    let redirect = false

    try {
      const body = {
        redirect_type: selectedPlatform.pType
      }
      const r = await redirectService.enter(body)

      if (r && r.id) {
        auth.setCurrentRedirectToken(r.redirect_token)

        const site = PlatformSites.find(e => e.type === body.redirect_type)
        let url = ''

        if (site) {
          url = build === 'dev' ? site.urlDev : build === 'staging' ? site.urlStaging : site.urlProd
          url += `/redirect?key=${r.redirect_token}`

          auth.setCurrentRedirectToken(r.redirect_token)
          setTimeout(() => {
            window.location.href = url
          }, 1000)

          redirect = true
        }
      }
    } catch (e) {
      console.log('e', e)
    }

    if (!redirect) {
      setIsUpdate(false)
      Notify.error(`Unable to navigate to ${selectedPlatform.pName}`, 'Please try again later.')

      setTimeout(() => {
        triggerRedirectModal(false)
      }, 200)
    }
  }

  return (
    <Spin size='large' spinning={isUserLoading || isUpdate}>
      <Page.Body>
        <Page.Header title={`Select Available Platform`}>
          <div>Here are the available platform. Click on any platform below and the site will redirect to the desired location.</div>
        </Page.Header>
        <Page.Content className='dsh'>
          <div className='panel-row'>
            { validator.isNotEmptyArray(list) && !isUserLoading
              ? list.map(e => {
                // console.log('list i', e)
                const pImage = e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ADMIN
                  ? <img alt='Portal Master' src={process.env.PUBLIC_URL + '/img/login-gateway.jpg'} className='banner-img' />
                  : e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ORG
                    ? <img alt='Portal Org' src={process.env.PUBLIC_URL + '/img/login-gateway.jpg'} className='banner-img' />
                    : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_PM
                      ? <img alt='Pumpkin PM' src={process.env.PUBLIC_URL + '/img/login-pm.svg'} className='banner-img' />
                      : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_SC
                        ? <img alt='Pumpkin SC' src={process.env.PUBLIC_URL + '/img/login-sc.svg'} className='banner-img' />
                        : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_ROSTER
                          ? <img alt='Pumpkin Roster' src={process.env.PUBLIC_URL + '/img/login-roster.svg'} className='banner-img' />
                          : ''

                const pName = e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ADMIN
                  ? `Pumpkin Portal (Admin)`
                  : e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ORG
                    ? `Pumpkin Portal (Organisation)`
                    : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_PM
                      ? `Pumpkin PM`
                      : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_SC
                        ? `Pumpkin SC`
                        : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_ROSTER
                          ? `Pumpkin Roster`
                          : ''

                const pNameColor = e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ADMIN
                  ? '#e84e1c'
                  : e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ORG
                    ? '#e84e1c'
                    : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_PM
                      ? '#2090FF'
                      : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_SC
                        ? '#fba742'
                        : e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_ROSTER
                          ? '#1d2c46'
                          : ''

                const pOrg = e.type === SSOPlatformType.SSO_PLATFORM_PORTAL_ORG || e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_PM || e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_SC || e.type === SSOPlatformType.SSO_PLATFORM_PUMPKIN_ROSTER
                  ? <div className='org-row'>
                    { e.org_thumb_url ? <img alt='Org Img' src={e.org_thumb_url} className='org-img' /> : null }
                    <div className='org-title'>{ e.org_name }</div>
                  </div>
                  : null

                const body = {
                  pImage,
                  pName,
                  pNameColor,
                  pType: e.type
                }

                return (
                  <div className='panel-tc' key={`panelc-${e.type}`} onClick={() => triggerRedirectModal(true, body)}>
                    <div className='banner-row'>
                      { pImage }
                      <div className='banner-col'>
                        <div className='banner-title' style={{color: pNameColor}}>{ pName }</div>
                        { pOrg }
                        <div className='org-pos'>{ e.role_name }</div>
                      </div>
                    </div>
                  </div>
                )
              })
              : isUserLoading
                ? <div className='subtitle'>Getting available platform...</div>
                : <div className='subtitle'>No platform available. Please contact your admin for more details.</div>}
          </div>
        </Page.Content>
      </Page.Body>

      <Modal
        visible={showRedirectModal}
        // title={'Logout'}
        onOk={isUpdate ? null : redirect}
        onCancel={isUpdate ? null : () => triggerRedirectModal(false)}
        footer={[
          <Button key='back' shape='round' onClick={isUpdate ? null : () => triggerRedirectModal(false)}>
            Cancel
          </Button>,
          <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : redirect}>
            Confirm
          </Button>
        ]}
      >
        <div className='modal-tc'>
          <div className='modal-row-center'>
            { selectedPlatform.pImage ? selectedPlatform.pImage : null }
          </div>
          <div className='modal-row' style={{marginTop: '15px'}}>
            <ExclamationCircleOutlined style={{color: 'red', fontSize: '20px'}} />
            <span style={{marginLeft: '10px'}}>Are you sure you want to navigate to <span className='modal-title' style={{color: selectedPlatform.pNameColor}}>{ selectedPlatform.pName }</span> ?</span>
          </div>
        </div>
      </Modal>
    </Spin>
  )
}

export default Dashboard
