import './styles.css'
import { Button } from 'antd'

function MenuSide ({ visible = false, userInfo = {}, onLogout = () => {} }) {
  return (
    <div className={`App-MenuSide ${visible ? 'show' : 'hide'}`}>
      {/* <div className='header'>{userInfo.name}</div> */}
      <div className='content'>
        <div className='row'>
          <div className='item-title' onClick={onLogout}>Logout</div>
        </div>
      </div>
    </div>
  )
}

export default MenuSide
