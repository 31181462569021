import { auth, localStorage, queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlAuth = '/api/auth'
const urlAuthPrivate = '/private/api/auth'

export default {
  login (values) {
    return request(`${urlAuth}/login`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  logout () {
    const token = auth.getCurrentToken()
    const user = auth.getCurrentUser()
    const values = { token, user }
    return request(`${urlAuth}/logout`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  resetPassword (values) {
    return request(`${urlAuth}/reset-password`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  switchMode (values) {
    return request(`${urlAuthPrivate}/switch-mode`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  redirect (values) {
    return request(`${urlAuthPrivate}/redirect`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
