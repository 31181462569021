import React, { useEffect, useState } from 'react'
import { useNavigate, useRoutes, BrowserRouter as Router, Route, Outlet } from 'react-router-dom'

import { RiNotification2Fill, RiMenuFill, RiMenu2Fill, RiBuilding4Fill, RiFunctionLine, RiShieldUserFill, RiShieldUserLine } from 'react-icons/ri'

import routes from '../../routes'
import { auth, localStorage, sessionStorage, formatter, validator } from '../../util'
import { loginService, userService } from '../../services'
import { Button, Icon, Modal, notification, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { UserStore } from '../../stores'
import MenuSideModal from '../../components/witty-ui/Modal/MenuSide'
import { build } from '../../config'

import './styles.css'

function App () {
  const navigate = useNavigate()

  const [userInfo, setUserInfo] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useEffect(() => {
    initUserInfo()
    getUserPlatformList()
  }, [])

  async function getUserPlatformList () {
    UserStore.update(e => {
      e.isUserLoading = true
    })

    const r = await userService.get()

    if (r && validator.isNotEmptyArray(r.list)) {
      UserStore.update(e => {
        e.isUserLoading = false
        e.userPlatformList = r.list
      })
    }
  }

  function initUserInfo () {
    let info = auth.getCurrentUser()
    if (info) {
      setUserInfo(info)
    }
  }

  function triggerLogoutModal (isLogout) {
    setShowLogoutModal(isLogout)
  }

  function turnOffModals () {
    if (showLogoutModal) setShowLogoutModal(false)
  }

  function turnOffLayouts () {
    if (showMenu) setShowMenu(false)
  }

  async function logout () {
    setIsUpdate(true)
    try {
      const r = await loginService.logout()
      if (r && r.id) {
        await localStorage.clear()
        await sessionStorage.clear()
        await localStorage.setItem('redirected', 'true')
        navigate('/login', { replace: true })
        window.location.href = '/login'
      }
      setIsUpdate(false)
    } catch (e) {

    }
  }

  return (
    <div className='App'>
      <Spin size='large' spinning={isUpdate}>
        <header className='App-header'>
          <div className='left'>
            <img className='App-logo logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-trans.png'} />
            <span style={{ fontSize: '15pt', color: '#e84e1c', fontWeight: 'bold' }} >Pumpkin Hub<span style={{ fontSize: '11pt', color: '#e84e1c77', fontWeight: 'bold', paddingLeft: '6px' }}>{build === 'dev' ? 'dev' : build === 'staging' ? 'staging' : ''}</span></span>
          </div>
          <div className='right'>
            <div className='menu-no-hover'>
              <div className='avatar row-flex-start'>
                <RiShieldUserLine color='#e84e1c' style={{ fontSize: '23px', marginRight: '5px' }} />
                <div className='name-app'>{userInfo.name}</div>
              </div>
            </div>
            <div className='menu'>
              <div className='avatar row-flex-start' onClick={() => { turnOffModals(); setShowMenu(!showMenu) }}>
                <RiMenuFill color='#e84e1c' style={{ fontSize: '23px', marginRight: '5px' }} />
              </div>
            </div>
          </div>
        </header>

        <MenuSideModal
          visible={showMenu}
          userInfo={userInfo}
          onLogout={() => triggerLogoutModal(true)}
        />

        <div className='App-body' onClick={turnOffLayouts}>
          { useRoutes(routes) }
        </div>

        <Modal
          visible={showLogoutModal}
          onOk={isUpdate ? null : logout}
          onCancel={isUpdate ? null : () => triggerLogoutModal(false)}
          footer={[
            <Button key='back' shape='round' onClick={isUpdate ? null : () => triggerLogoutModal(false)}>
              Cancel
            </Button>,
            <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : logout}>
              Confirm
            </Button>
          ]}
        >
          <div className='row-flex-start'>
            <ExclamationCircleOutlined style={{color: 'red', fontSize: '20px'}} />
            <div style={{marginLeft: '10px'}}>Are you sure you want to logout?</div>
          </div>
        </Modal>
      </Spin>
    </div>
  )
}

export default App
